import { Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import questionType from '../../../../common/enum/questiontype';
import { Answer } from '../../../../common/model/Answer';
import EmptyBox from './EmptyBox';

interface IQuestionNumberLists {
    changeMode: Function;
    setListeningStatus: Function;
}

const QuestionNumberLists = (props: IQuestionNumberLists) => {

    const answers: Answer[] = useSelector((state: any) => {
        return state.userReducer.answers;
    });
    const filter: string = useSelector((state: any) => state.userReducer.filter);
    const [listenings, setListening] = useState<Answer[]>([])
    const [readings, setReadings] = useState<Answer[]>([])

    useEffect(() => {
        console.log(answers);

        // setListening(answers.filter(q => (q.type === questionType.LISTENING && (filter === "all" ? true : filter === "solved" ? q.answer !== "0" : q.answer === "0"))))
        // setReadings(answers.filter(q => (q.type === questionType.READING && (filter === "all" ? true : filter === "solved" ? q.answer !== "0" : q.answer === "0"))))
        setListening(answers.filter(q => (q.type === questionType.LISTENING )))
        setReadings(answers.filter(q => (q.type === questionType.READING)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers, filter])


    return (
        <>
            <Stack direction={'row'}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Box sx={{ p: 2, border: '1px solid grey' }}>
                            <div className="flex justify-center align-items mb-sm text-primary bold">
                                Reading Questions
                            </div>
                            <Grid container spacing={1}>
                                {readings.length > 0 ? readings.map((answer) => (
                                    <Grid item xs={2.4}>
                                        <div onClick={() => props.changeMode(answer.question)} className={`pointer pa-sm flex justify-center align-items bold border-1px  ${(filter === "all" ? answer.answer !== '0' ? 'bg-primary text-light' : '' : (filter === "solved" ? (answer.answer !== "0" ? '' : 'text-light') : filter === "unsolved" && (answer.answer !== "0" ? 'text-light' : '')))}  `}>
                                            {answer.questionNumber}
                                            {/* {fil} */}{" "}
                                        </div>
                                    </Grid>

                                )) :
                                    <EmptyBox message={'No Reading Questions'} />
                                }
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Box sx={{ p: 2, border: '1px solid grey' }}>
                            <div className="flex justify-center align-items mb-sm text-primary bold">
                                Listening Questions
                            </div>
                            <Grid container spacing={1}>
                                {listenings.length > 0 ? listenings.map((answer) => (
                                    <Grid item xs={2.4}>
                                        <div onClick={() => props.changeMode(answer.question)} className={`pointer pa-sm flex justify-center align-items bold border-1px ${(filter === "all" ? answer.answer !== '0' ? 'bg-primary text-light' : '' : (filter === "solved" ? (answer.answer !== "0" ? '' : 'text-light') : filter === "unsolved" && (answer.answer !== "0" ? 'text-light' : '')))}  `}>
                                            {answer.questionNumber}
                                        </div>
                                    </Grid>
                                )) :
                                    <EmptyBox message={'No Listening Questions'} />}
                            </Grid>
                        </Box>
                    </Grid>

                </Grid>


            </Stack>
        </>
    );
};

export default QuestionNumberLists;
