import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles'
import React from 'react';
import './App.css';
import MainLayout from './hoc/MainLayout';

const theme = createTheme();



function App() {
  return (
    <>
      <ThemeProvider theme={theme}><MainLayout /></ThemeProvider>

    </>
  );
}

export default App;
